import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import SideBarMenu from "@components/sidebar/sidebar"
import Sticky from "react-sticky-el"
import { Modal } from "react-bootstrap"
// images
import LogoImageOnly from "@images/logo-img-only.png"
import LogoTextOnly from "@images/logo-txt-only.png"
import CallIcon from "@images/call.svg"
import LocationIcon from "@images/pin.svg"
// import ShopIcon from "@images/shopping-bag.svg"
import ShoppingBag from "../ecwid_widgets/ShoppingBag"
import ProductSearch from "../ecwid_widgets/ProductSearch"

const Header = ({ headerType, location }) => {
  const dataNav = useStaticQuery(graphql`
    query headerQuery {
      allSanityNavItemSch(sort: { fields: navOrder, order: ASC }) {
        edges {
          node {
            id
            name
            link {
              current
            }
            haveDropdown
            dropdownItem {
              navigationItem
              navigationUrl
            }
          }
        }
      }
    }
  `)
  // console.log("location", location.pathname.includes("denver"))

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  console.log("dataNav", dataNav)
  return (
    <header>
      <Modal size="lg" show={show} onHide={handleClose} centered>
        {" "}
        <svg
          onClick={handleClose}
          xmlns="http://www.w3.org/2000/svg"
          className="h-1 w-1"
          viewBox="0 0 20 20"
          fill="currentColor"
          width="25"
          height="25"
          style={{
            position: "absolute",
            right: "5",
            top: "5",
            zIndex: "10",
            cursor: "pointer",
          }}
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clipRule="evenodd"
          />
        </svg>
        <Modal.Body style={{ padding: "30px 0" }}>
          <iframe
            src="https://prs.builderleadconverter.com/pr/pQx7NGI2"
            width="100%"
            height="900"
            frameborder="0"
          ></iframe>
        </Modal.Body>
      </Modal>
      <div className="header-top-bar d-none d-md-block">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="d-inline-block">
                <img src={LocationIcon} alt="" />
                <span>
                   709 Nichols Blvd., Unit A, Colorado Springs, CO 80907
                </span>
              </div>
              <div className="d-inline-block ml-4">
                <img src={CallIcon} alt="" />
                <a href="tel:719-985-3336">(719) 985-3336</a>
              </div>
            </div>
            {/*do not show socials on small screens*/}
            <div>
              <div className="d-inline-flex align-items-center">
                <Link
                  className="d-inline-block mr-lg-4 text-uppercase"
                  style={{ whiteSpace: "nowrap" }}
                  to="/blog"
                >
                  Blog
                </Link>
                <Link
                  className="d-inline-block mr-lg-4 text-uppercase"
                  style={{ whiteSpace: "nowrap" }}
                  to="/contact-us"
                >
                  Contact Us
                </Link>
                <div className="d-none d-lg-inline-flex">
                  <a
                    className="header-social"
                    href="https://www.facebook.com/edenoakswoodware"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a
                    className="header-social"
                    href="https://twitter.com/EdenOaks"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a
                    className="header-social"
                    href="https://www.instagram.com/edenoaks/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                  <a
                    className="header-social"
                    href="https://www.pinterest.com/Edenoaks/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-pinterest"></i>
                  </a>

                  <a
                    className="header-social"
                    href="https://www.houzz.com/pro/freddie-provenzano/eden-oaks-woodware"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-houzz"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sticky
        className="sticky-wrapper"
        stickyClassName="is-sticky"
        stickyStyle={{ transform: "unset", zIndex: "99" }}
      >
        <div
          className={`header ${headerType === "solid" ? "solid-header" : ""} `}
        >
          <div className="container">
            <div className="d-flex justify-content-between align-items-center header-content-wrapper">
              <Link
                to={location?.pathname?.includes("denver") ? "/denver" : "/"}
                className="header-logo d-flex align-items-center"
              >
                <img
                  className="header-logo-img img-fluid"
                  src={LogoImageOnly}
                  alt="Eden Oaks Designer Woodware"
                />
                {/* <img
                  className="header-logo-text img-fluid d-none d-sm-inline-flex"
                  src={LogoTextOnly}
                  alt="Eden Oaks Designer Woodware"
                /> */}
              </Link>

              {/* full header navigation if screen is at least 1200px */}
              <div className="full-header-nav align-items-center">
                <div className="d-none d-xl-inline-flex nav-menu">
                  {dataNav.allSanityNavItemSch.edges.map(({ node }) => (
                    <React.Fragment key={node.id}>
                      {node?.haveDropdown ? (
                        <div key={node.id} className="link-wrapper has-submenu">
                          <span className="no-link">{node.name}</span>
                          <div className="submenu-wrapper">
                            {node?.dropdownItem?.map((item, index) => (
                              <Link
                                key={index}
                                to={
                                  location?.pathname?.includes("denver")
                                    ? `/denver/${item?.navigationUrl}`
                                    : `/${item?.navigationUrl}`
                                }
                              >
                                {item?.navigationItem}
                              </Link>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`link-wrapper ${
                            node.name === "Price Out Your Project"
                              ? "price-out-link"
                              : ""
                          }`}
                          key={node.id}
                        >
                          {node.link.current === "home" ? (
                            <Link
                              to={
                                location?.pathname?.includes("denver")
                                  ? "/denver"
                                  : "/"
                              }
                            >
                              {node?.name}
                            </Link>
                          ) : (
                            <Link
                              to={
                                location?.pathname?.includes("denver") &&
                                node.name !== "Blog" &&
                                node.name !== "Connect" &&
                                node.name !== "About" &&
                                node.name !== "Shop" &&
                                node.name !== "Quote"
                                  ? `/denver/${node?.link?.current}`
                                  : node.link.current === "home"
                                  ? "/"
                                  : node.name !== "Price Out Your Project"
                                  ? `/${node?.link?.current}`
                                  : `${location?.pathname}`
                              }
                              onClick={(e) =>
                                node.name === "Price Out Your Project"
                                  ? handleShow(e)
                                  : null
                              }
                            >
                              {node?.name}
                            </Link>
                          )}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>

                <div className="link-wrapper has-submenu location-selector">
                  {location?.pathname?.includes("denver") ? (
                    <>
                      <span className="no-link">DEN</span>
                      <div className="submenu-wrapper">
                        <Link to="/">
                          <span className="font-weight-bold text-body">CO</span>{" "}
                          (Colorado Springs)
                        </Link>
                        <Link to="/">
                          <span className="font-weight-bold text-body">CO</span>{" "}
                          (Fort Collins)
                        </Link>
                        <Link to="/">
                          <span className="font-weight-bold text-body">CO</span>{" "}
                          (Boulder)
                        </Link>
                        <Link to="/">
                          <span className="font-weight-bold text-body">CO</span>{" "}
                          (Pueblo)
                        </Link>
                        <Link to="/">
                          <span className="font-weight-bold text-body">CO</span>{" "}
                          (Breckenridge)
                        </Link>
                        <Link to="/">
                          <span className="font-weight-bold text-body">CO</span>{" "}
                          (Vail)
                        </Link>
                        <Link to="/">
                          <span className="font-weight-bold text-body">CO</span>{" "}
                          (Longmont)
                        </Link>
                        <Link to="/">
                          <span className="font-weight-bold text-body">CO</span>{" "}
                          (Loveland)
                        </Link>
                        <Link to="/">
                          <span className="font-weight-bold text-body">CO</span>{" "}
                          (Greely)
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      <span className="no-link">CO</span>
                      <div className="submenu-wrapper">
                        <Link to="/denver">
                          <span className="font-weight-bold text-body">
                            DEN
                          </span>{" "}
                          (Denver)
                        </Link>
                        <Link to="/">
                          <span className="font-weight-bold text-body">CO</span>{" "}
                          (Fort Collins)
                        </Link>
                        <Link to="/">
                          <span className="font-weight-bold text-body">CO</span>{" "}
                          (Boulder)
                        </Link>
                        <Link to="/">
                          <span className="font-weight-bold text-body">CO</span>{" "}
                          (Pueblo)
                        </Link>
                        <Link to="/">
                          <span className="font-weight-bold text-body">CO</span>{" "}
                          (Breckenridge)
                        </Link>
                        <Link to="/">
                          <span className="font-weight-bold text-body">CO</span>{" "}
                          (Vail)
                        </Link>
                        <Link to="/">
                          <span className="font-weight-bold text-body">CO</span>{" "}
                          (Longmont)
                        </Link>
                        <Link to="/">
                          <span className="font-weight-bold text-body">CO</span>{" "}
                          (Loveland)
                        </Link>
                        <Link to="/">
                          <span className="font-weight-bold text-body">CO</span>{" "}
                          (Greely)
                        </Link>
                      </div>
                    </>
                  )}
                </div>

                {/* <div className="link-wrapper shop-link-wrapper">
                  <ProductSearch />
                  <ShoppingBag />
                  <Link to="/">
                    <img src={ShopIcon} alt="shop" width="18px" />
                  </Link>
                </div> */}
                <div className="d-inline-flex pl-3 d-xl-none">
                  <SideBarMenu location={location} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sticky>
    </header>
  )
}

export default Header
