import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "@components/header/header"
import Footer from "@components/footer/footer"
import "bootstrap/dist/css/bootstrap.css"
import "font-awesome/css/font-awesome.min.css"
import "@sass/main.scss"

import useScript from "../../hooks/useScript"

const Layout = ({ headerType, children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let script_status = useScript(
    `https://app.shopsettings.com/script.js?${
      process.env.ECWID_STORE_ID || "57240192"
    }&data_platform=code&data_date=2021-04-28`
  )

  React.useEffect(() => {
    if (script_status === "ready") {
      try {
        window.Ecwid.OnAPILoaded.add(function () {
          window.Ecwid.init()
          // window.xSearch("id=my-search-57240192")
        })
      } catch (error) {
        console.log(error)
      }
    }
  }, [script_status])

  // When embedded in an iFrame, sends a postMessage "WEBRIQ_SITE_HAS_LOADED" to parent
  const [hasLoaded, setHasLoaded] = React.useState(false)
  React.useEffect(() => {
    const sendPostMessage = (e) => {
      if (!hasLoaded) {
        // console.log("Sending postMessage: WEBRIQ_SITE_HAS_LOADED")
        window.parent.postMessage("WEBRIQ_SITE_HAS_LOADED", "*")
        // console.log("Successfully sent postMessage: WEBRIQ_SITE_HAS_LOADED")
        setHasLoaded(true)
      }
    }

    sendPostMessage()
  }, [hasLoaded])

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        headerType={headerType}
        location={location}
      />
      <main>{children}</main>
      <Footer location={location} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
