import React from "react"
import { BsSearch } from "react-icons/bs"

const ProductSearch = () => {
  return (
    <>
      <a id="my-search-57240192" href="#!/~/search" className="text-white">
        <BsSearch style={{ fontSize: 24 }} />
      </a>
    </>
  )
}

export default ProductSearch
