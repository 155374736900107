import React, { useState } from "react"
import { Modal } from "react-bootstrap"

const ModalPrice = ({ location }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  console.log("location", location)
  return (
    <>
      <button
        onClick={handleShow}
        className={`btn btn-primary mt-5 ${
          location?.pathname !== "/about" &&
          location?.pathname !== "/doors" &&
          location?.pathname !== "/residential" &&
          location?.pathname !== "/accent-wall" &&
          location?.pathname !== "/floatingshelves"
            ? "d-none"
            : ""
        }`}
        type="submit"
        style={{
          transform: "scale(1.15)",
          transformOrigin: "top left",
        }}
      >
        Price Out Your Project
      </button>

      <Modal size="lg" show={show} onHide={handleClose} centered>
        <svg
          onClick={handleClose}
          xmlns="http://www.w3.org/2000/svg"
          className="h-1 w-1"
          viewBox="0 0 20 20"
          fill="currentColor"
          width="25"
          height="25"
          style={{
            position: "absolute",
            right: "5",
            top: "5",
            zIndex: "10",
            cursor: "pointer",
          }}
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clipRule="evenodd"
          />
        </svg>
        <Modal.Body style={{ padding: "30px 0" }}>
          <iframe
            src="https://prs.builderleadconverter.com/pr/pQx7NGI2"
            width="100%"
            height="900"
            frameborder="0"
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ModalPrice
