import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Nav, Accordion, Card } from "react-bootstrap"
import closeBtn from "@images/close-btn.svg"
import CallIcon from "@images/call.svg"

const slugify = require("slugify")

const MenuLinks = ({ toggle, location }) => {
  const dataNav = useStaticQuery(graphql`
    query menuQuery {
      allSanityNavItemSch(sort: { fields: navOrder, order: ASC }) {
        edges {
          node {
            id
            name
            link {
              current
            }
            haveDropdown
            dropdownItem {
              navigationItem
              navigationUrl
            }
          }
        }
      }
    }
  `)
  // console.log("dataNav", dataNav)
  return (
    <div className="navigation">
      <div className="close-button">
        <div className="text-right">
          <button className="close-btn btn-unstyled" onClick={toggle}>
            X
          </button>
        </div>
        <Nav className="flex-column sidebar-menu">
          <Accordion>
            {dataNav.allSanityNavItemSch.edges.map(({ node }) => (
              <React.Fragment key={node.id}>
                {node?.haveDropdown ? (
                  <Card key={node.id}>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={slugify(node.name.toLowerCase())}
                    >
                      <span className="d-inline-block">{node.name}</span>
                    </Accordion.Toggle>
                    <Accordion.Collapse
                      eventKey={slugify(node.name.toLowerCase())}
                    >
                      <Card.Body>
                        {node?.dropdownItem?.map((item, index) => (
                          <Nav.Item key={index}>
                            <Link
                              to={
                                location?.pathname?.includes("denver")
                                  ? `/denver/${item?.navigationUrl}`
                                  : `/${item?.navigationUrl}`
                              }
                            >
                              {item?.navigationItem}
                            </Link>
                          </Nav.Item>
                        ))}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ) : (
                  <Nav.Item key={node.id}>
                    {node.link.current === "home" ? (
                      <Link
                        to={
                          location?.pathname?.includes("denver")
                            ? "/denver"
                            : "/"
                        }
                      >
                        {node?.name}
                      </Link>
                    ) : (
                      <Link
                        to={`/${node?.link?.current}`}
                        className={`wrapper-link ${
                          node.name === "Price Out Your Project"
                            ? "price-out-link d-none"
                            : ""
                        }`}
                        key={node.id}
                      >
                        {node?.name}
                      </Link>
                    )}
                  </Nav.Item>
                )}
              </React.Fragment>
            ))}
          </Accordion>
        </Nav>
        <div className="pt-3 text-center">
          <a className="btn btn-primary my-2" href="tel:719-985-3336">
            <img src={CallIcon} width="14px" className="pr-1" alt="" />
            (719) 985-3336
          </a>
        </div>

        <div className="pt-3 d-flex justify-content-center">
          <a
            className="sidebar-social"
            href="https://www.facebook.com/edenoakswoodware"
            target="_blank"
            rel="noreferrer"
            aria-label="Facebook"
          >
            <i className="fa fa-facebook"></i>
          </a>
          <a
            className="sidebar-social"
            href="https://twitter.com/EdenOaks"
            target="_blank"
            rel="noreferrer"
            aria-label="Twitter"
          >
            <i className="fa fa-twitter"></i>
          </a>
          <a
            className="sidebar-social"
            href="https://www.instagram.com/edenoaks/"
            target="_blank"
            rel="noreferrer"
            aria-label="Instagram"
          >
            <i className="fa fa-instagram"></i>
          </a>
          <a
            className="sidebar-social"
            href="https://www.pinterest.com/Edenoaks/"
            target="_blank"
            rel="noreferrer"
            aria-label="Pinterest"
          >
            <i className="fa fa-pinterest"></i>
          </a>

          <a
            className="sidebar-social"
            href="https://www.houzz.com/pro/freddie-provenzano/eden-oaks-woodware"
            target="_blank"
            rel="noreferrer"
            aria-label="Houzz"
          >
            <i className="fa fa-houzz"></i>
          </a>
        </div>
      </div>
    </div>
  )
}

export default MenuLinks
