import React from "react"
import { Link } from "gatsby"
import { Accordion, Card, Button } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import NewsletterForm from "@webriq/gatsby-webriq-form"
import Modal from "../modal"

import newsletterBg from "@images/newsletter-bg.jpg"

const footer = ({ location }) => {
  console.log("location", location)
  return (
    <footer>
      <div className="subscribe-section">
        <div className="container subscribe-content">
          <Fade>
            <div className="section-heading text-left">
              <h2 className="text-white">Subscribe to our newsletter</h2>
            </div>
            <div className="row justify-content-start">
              <div className="col-lg-9">
                <p className="my-3 text-left text-white">
                  Receive updates straight to your inbox.
                </p>
              </div>
            </div>
          </Fade>

          <NewsletterForm
            name="Newsletter Form"
            data-form-id="77707fe2-2761-4b08-8003-a098af3150aa"
            webriq="true"
          >
            <div className="form-row">
              <div className="col-md-4">
                <div className="form-group">
                  <input
                    id="newsletter-email"
                    maxLength="60"
                    name="email"
                    size="20"
                    type="email"
                    undefined=""
                    required=""
                    className="form-control"
                    placeholder="Your Email Address"
                    name="Email Address"
                  />
                  <label htmlFor="newsletter-email">Your Email Address</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <input
                    id="newsletter-name"
                    maxLength="60"
                    name="field_firstname"
                    size="20"
                    type="text"
                    className="form-control"
                    placeholder="Your Name"
                    name="Name"
                  />
                  <label htmlFor="newsletter-name">Your Name</label>
                </div>
              </div>
              <div className="webriq-recaptcha" />
              <div className="col">
                <div className="form-group">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    style={{
                      transform: "scale(1.15)",
                      transformOrigin: "top left",
                    }}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </NewsletterForm>

          {/* <a
            href="/"
            className={`btn btn-primary mt-5 ${
              location?.pathname !== "/about" &&
              location?.pathname !== "/doors" &&
              location?.pathname !== "/residential" &&
              location?.pathname !== "/accent-wall" &&
              location?.pathname !== "/floatingshelves"
                ? "d-none"
                : ""
            }`}

            type="submit"
            style={{
              transform: "scale(1.15)",
              transformOrigin: "top left",
            }}
          >
            Price Out Your Project
          </a> */}
          <Modal location={location} />
        </div>
        <div className="background-image">
          <div
            className="image-bg"
            style={{ backgroundImage: `url(${newsletterBg})` }}
          ></div>
        </div>
      </div>
      <div className="links-section">
        <div className="container">
          <Fade>
            <div className="links-row-wrapper">
              <div className="row text-center text-md-left justify-content-between">
                <div className="col-4 col-md-2 mb-5 mb-md-0">
                  <h2 className="h6 font-weight-bold mb-3">Quick Links</h2>
                  <ul>
                    <li>
                      <Link
                        to={
                          location?.pathname?.includes("denver")
                            ? "/denver"
                            : "/"
                        }
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          location?.pathname?.includes("denver")
                            ? "/denver/gallery"
                            : "/gallery"
                        }
                      >
                        Gallery
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/gallery">Gallery</Link>
                    </li> */}
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link
                        to={
                          location?.pathname?.includes("denver")
                            ? "/contact-us"
                            : "/contact-us"
                        }
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/sitemap">HTML Sitemap</Link>
                    </li>
                    <li>
                      <Link to="/sitemap.xml">XML Sitemap</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-4 col-md-2 mb-5 mb-md-0">
                  <h2 className="h6 font-weight-bold mb-3">Products</h2>
                  <ul>
                    <li>
                      <Link
                        to={
                          location?.pathname?.includes("denver")
                            ? "/denver/accent-wall"
                            : "/accent-wall"
                        }
                      >
                        Accent Wall
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          location?.pathname?.includes("denver")
                            ? "/denver/beams"
                            : "/beams"
                        }
                      >
                        Beams
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          location?.pathname?.includes("denver")
                            ? "/denver/cabinetry"
                            : "/cabinetry"
                        }
                      >
                        Cabinetry
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          location?.pathname?.includes("denver")
                            ? "/denver/countertops"
                            : "/countertops"
                        }
                      >
                        Countertops
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          location?.pathname?.includes("denver")
                            ? "/denver/custom-furniture"
                            : "/custom-furniture"
                        }
                      >
                        Custom Furniture
                      </Link>
                    </li>

                    <li>
                      <Link
                        to={
                          location?.pathname?.includes("denver")
                            ? "/denver/doors"
                            : "/doors"
                        }
                      >
                        Doors
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          location?.pathname?.includes("denver")
                            ? "/denver/floatingshelves"
                            : "/floatingshelves"
                        }
                      >
                        Floating Shelves
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          location?.pathname?.includes("denver")
                            ? "/denver/islands"
                            : "/islands"
                        }
                      >
                        Islands
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          location?.pathname?.includes("denver")
                            ? "/denver/mantel"
                            : "/mantel"
                        }
                      >
                        Mantel
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          location?.pathname?.includes("denver")
                            ? "/denver/materials"
                            : "/materials"
                        }
                      >
                        Materials
                      </Link>
                    </li>

                    <li>
                      <Link
                        to={
                          location?.pathname?.includes("denver")
                            ? "/denver/railings"
                            : "/railings"
                        }
                      >
                        Railings
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-4 col-md-2 mb-5 mb-md-0">
                  <h2 className="h6 font-weight-bold mb-3">Design</h2>
                  <ul>
                    <li>
                      <Link
                        to={
                          location?.pathname?.includes("denver")
                            ? "/denver/residential"
                            : "/residential"
                        }
                      >
                        Residential
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          location?.pathname?.includes("denver")
                            ? "/denver/commercial"
                            : "/commercial"
                        }
                      >
                        Commercial
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-12 col-md-4">
                  <h2 className="h6 font-weight-bold mb-3">
                    Eden Oaks Colorado Springs
                  </h2>
                  <ul>
                    <li>
                      709 Nichols Blvd., Unit A, Colorado Springs, CO 80907
                    </li>
                    <li>
                      <a className="contact-link" href="tel:719-985-3336">
                        (719) 985-3336
                      </a>
                    </li>
                    <li>
                      <a
                        className="contact-link"
                        href="mailto:freddie@edenoaksco.com"
                      >
                        freddie@edenoaksco.com
                      </a>
                    </li>
                  </ul>
                  <div className="mt-5">
                    <div className="d-flex justify-content-center justify-content-md-start">
                      <a
                        className="footer-social"
                        href="https://www.facebook.com/edenoakswoodware"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Facebook"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a
                        className="footer-social"
                        href="https://twitter.com/EdenOaks"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Twitter"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a
                        className="footer-social"
                        href="https://www.instagram.com/edenoaks/"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Instagram"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                      <a
                        className="footer-social"
                        href="https://www.pinterest.com/Edenoaks/"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Pinterest"
                      >
                        <i className="fa fa-pinterest"></i>
                      </a>

                      <a
                        className="footer-social"
                        href="https://www.houzz.com/pro/freddie-provenzano/eden-oaks-woodware"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Houzz"
                      >
                        <i className="fa fa-houzz"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
          <div className="pb-5">
            <div className="d-flex justify-content-between">
              <span className="small text-muted text-center text-md-left">
                ©{new Date().getFullYear()} Eden Oaks Designer Woodware. All
                rights reserved.
              </span>
              <div className="collapse-wrapper d-none d-md-block">
                <div className="footer-location-selector">
                  <Accordion>
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="0"
                          className="btn-unstyled location-selector-btn"
                        >
                          <span className="small">
                            {location?.pathname?.includes("denver")
                              ? "Denver"
                              : "Colorado Springs"}

                            <i className="fa fa-angle-up ml-1"></i>
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <ul className="locations-list list-unstyled p-0">
                            <li>
                              {location?.pathname?.includes("denver") ? (
                                <Link to="/">Colorado Springs</Link>
                              ) : (
                                <Link to="/denver">Denver</Link>
                              )}
                            </li>
                          </ul>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default footer
